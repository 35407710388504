import React from "react";
import {
  Toolbar,
  useScrollTrigger,
  AppBar,
  useMediaQuery,
  Box,
} from "@mui/material";
import MainAppbar from "../../../../Components/Widgets/MainAppbar";
import Slider from "../../../../Components/Widgets/Slider";
import { useTheme } from "@mui/material/styles";

export default function Navbar(props) {
  const { component } = props;
  const { slider = false } = component.props;

  const theme = useTheme();
  const appbarHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.appbar_height.desktop
    : theme.appbar_height.mobile;

  const sliderHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.slider_height.desktop
    : theme.slider_height.mobile;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: parseInt(appbarHeight),
  });

  return (
    <>
      <AppBar
        position={slider ? "relative" : "fixed"}
        elevation={slider ? 0 : 2}
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <MainAppbar {...props} />
        <Box sx={{ height: slider && trigger ? sliderHeight : "0px" }}></Box>
      </AppBar>
      {slider && (
        <AppBar
          position={trigger ? "fixed" : "relative"}
          elevation={trigger ? 2 : 0}
        >
          <Slider {...props} />
        </AppBar>
      )}
      <Box
        sx={{ display: slider ? "none" : "block", height: appbarHeight }}
      ></Box>
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </>
  );
}
