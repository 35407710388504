import Service from "../Service";
import Services from "../Services";

class SocialLogin extends Service {
  loadFB({ window, document, page }) {
    const facebookAppId = page.getHelpers("env").get("facebook_app_id");
    const facebookApiVersion = page
      .getHelpers("env")
      .get("facebook_api_version");

    if (facebookAppId && facebookApiVersion) {
      window.fbAsyncInit = function () {
        if (window.FB) {
          const FB = window.FB;

          FB.init({
            appId: facebookAppId,
            cookie: true,
            xfbml: true,
            version: facebookApiVersion,
          });

          FB.AppEvents.logPageView();
        }
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }

  loginFB({ window, document, page }) {
    if (window.FB) {
      const FB = window.FB;

      FB.login(
        (response) => {
          if (response.authResponse) {
            FB.api("/me?fields=name,id,email", (response) => {
              const { name, email } = response;

              if (name && email) {
                Services.get("auth").then(([authService]) => {
                  authService
                    .loginFB({ username: email, name })
                    .then((authService) => {
                      page.deleteData("error.message");
                      page
                        .getHelpers("Auth")
                        .set(authService.getData("_response").getData());

                      page
                        .getComponents()
                        .findById("login-register-dialog")
                        .forEach((dialog) => {
                          dialog.closeDialog();
                        });

                      page.login();
                    })
                    .catch((authService) => {
                      console.log(authService);
                      page.setData({
                        "error.message": authService
                          .getData("_response")
                          .getMessage(),
                      });
                    });
                });
              } else {
                alert(page.ucfirst("parmissions error"));
              }
            });
          }
        },
        { scope: "public_profile,email" }
      );
    }
  }

  loadGoogle({ window, document, page }) {
    const googleClientId = page.getHelpers("env").get("google_client_id");
    const element = document.getElementById("google-sign-in-btn");
    const googleSdk = document.getElementById("google-sdk");

    if (googleClientId && element) {
      if (googleSdk) {
        this.loadGoogleLogin({ window, document, page });
      }

      const onLoad = () => this.loadGoogleLogin({ window, document, page });

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://accounts.google.com/gsi/client";
        js.onload = () => onLoad();
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "google-sdk");
    }
  }

  loadGoogleLogin({ window, document, page }) {
    const googleClientId = page.getHelpers("env").get("google_client_id");

    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: (response) => {
        const { email, name } = JSON.parse(
          atob(response.credential.split(".")[1])
        );

        Services.get("auth", "test").then(([authService]) => {
          authService
            .loginGoogle({ username: email, name })
            .then((authService) => {
              page.deleteData("error.message");
              page
                .getHelpers("Auth")
                .set(authService.getData("_response").getData());
              // page.redirectToRedirect("/");
              page
                .getComponents()
                .findById("login-register-dialog")
                .forEach((dialog) => {
                  dialog.closeDialog();
                });

              page.login();
            })
            .catch((authService) => {
              console.log(authService);
              this.setData({
                "error.message": authService.getData("_response").getMessage(),
              });
            });
        });
      },
    });

    window.google.accounts.id.renderButton(
      document.getElementById("google-sign-in-btn"),
      { theme: "outline", size: "large" } // customization attributes
    );

    window.google.accounts.id.prompt();
  }

  // loadGoogle({ window, document, page }) {
  //   const googleClientId = page.getHelpers("env").get("google_client_id");
  //   const element = document.getElementById("google-sign-in-btn");
  //   const googleSdk = document.getElementById("google-sdk");

  //   if (googleClientId && element) {
  //     if (googleSdk) {
  //       this.loadGoogleLogin({ window, document, page });
  //     } else {
  //       window.googleSignInOnLoad = () => {
  //         this.loadGoogleLogin({ window, document, page });
  //       };
  //     }

  //     (function (d, s, id) {
  //       var js,
  //         fjs = d.getElementsByTagName(s)[0];
  //       if (d.getElementById(id)) {
  //         return;
  //       }
  //       js = d.createElement(s);
  //       js.id = id;
  //       js.src =
  //         "https://apis.google.com/js/api:client.js?onload=googleSignInOnLoad";
  //       fjs.parentNode.insertBefore(js, fjs);
  //     })(document, "script", "google-sdk");
  //   }
  // }

  // loadGoogleLogin({ window, document, page }) {
  //   const element = document.getElementById("google-sign-in-btn");
  //   const googleClientId = page.getHelpers("env").get("google_client_id");

  //   if (window.gapi) {
  //     const gapi = window.gapi;
  //     gapi.load("auth2", () => {
  //       const auth2 = gapi.auth2.init({
  //         client_id: googleClientId + ".apps.googleusercontent.com",
  //         // cookiepolicy: "single_host_origin",
  //         scope: "email",
  //       });

  //       auth2.attachClickHandler(
  //         element,
  //         {
  //           // prompt: "concent",
  //           scope: "email",
  //         },
  //         (googleUser) => {
  //           const basicProfile = googleUser.getBasicProfile();
  //           const name = basicProfile.getName();
  //           const username = basicProfile.getEmail();

  //           if (name && username) {
  //             Services.get("auth", "test").then(([authService]) => {
  //               authService
  //                 .loginGoogle({ username, name })
  //                 .then((authService) => {
  //                   page.deleteData("error.message");
  //                   page
  //                     .getHelpers("Auth")
  //                     .set(authService.getData("_response").getData());
  //                   // page.redirectToRedirect("/");
  //                   page
  //                     .getComponents()
  //                     .findById("login-register-dialog")
  //                     .forEach((dialog) => {
  //                       dialog.closeDialog();
  //                     });
  //                 })
  //                 .catch((authService) => {
  //                   this.setData({
  //                     "error.message": authService
  //                       .getData("_response")
  //                       .getMessage(),
  //                   });
  //                 });
  //             });
  //           } else {
  //             console.log("google sign error");
  //           }
  //         },
  //         (error) => {
  //           console.log(JSON.stringify(error, undefined, 2));
  //         }
  //       );
  //     });
  //   }
  // }
}

export default new SocialLogin();
