import React from "react";
import {
  Container,
  Box,
  Paper,
  List,
  Typography,
  Skeleton,
} from "@mui/material";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import ProductDialog from "../../../../Components/Widgets/Dialogs/ProductDialog";
import DealDialog from "../../../../Components/Widgets/Dialogs/DealDialog";
import ProductListSkeletonView from "../product_list_skeleton_view";
import Alert from "@mui/material/Alert";

export default function Products(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const isSpotOpened = component.isSpotOpened();

  const {
    products = [],
    types = [],
    ingredient_types = [],
  } = component.getData("default", {});

  return (
    <Box>
      {!isSpotOpened && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            my: 3,
            alignItems: "center",
          }}
          maxWidth="lg"
        >
          <Alert sx={{ maxWidth: 900, width: "100%" }} severity="error">
            {component.ucfirst("store-is-closed")}
          </Alert>
        </Container>
      )}

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 3,
          padding: { xs: 0, md: 1 },
        }}
        maxWidth="lg"
      >
        <Paper
          elevation={2}
          sx={{
            width: "100%",
            maxWidth: 900,
            padding: { xs: 0, sm: 2 },
          }}
        >
          <Box
            sx={{
              mx: { xs: 1, sm: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid #f05d32",
              cursor: "pointer",
              borderRadius: 1,
              textAlign: "center",
              fontWeight: 500,
              p: 1,
              mt: { xs: 1, sm: 0 },
              "& i": {
                my: 1,
                color: "#f05d32",
              },
            }}
            // onClick={() => {
            //   component
            //     .getComponents()
            //     .findById("offer-dialog")
            //     .first()
            //     .setData({
            //       "default.open": true,
            //     });
            // }}
          >
            <i className="fa-solid fa-minus fa-bounce" />
            <i className="fa-solid fa-1 fa-bounce" />
            <i className="fa-solid fa-5 fa-bounce" />
            <i className="fa-solid fa-percent fa-bounce" />
            <Box sx={{ mx: 1 }}>{component.trans("15-percent-discount")}</Box>
            <i className="fa-solid fa-minus fa-bounce" />
            <i className="fa-solid fa-1 fa-bounce" />
            <i className="fa-solid fa-5 fa-bounce" />
            <i className="fa-solid fa-percent fa-bounce" />
          </Box>
          {types.length !== 0 && products.length !== 0 ? (
            <>
              {types.map((type) => {
                var types_products = products.filter((product) =>
                  product._values.types.find(
                    (current) => current.item._values.slug === type._values.slug
                  )
                );

                if (types_products.length !== 0) {
                  return (
                    <Box
                      key={devHelper.getObjectValue(type, "_id")}
                      sx={{ px: { xs: 1, sm: 2 }, py: 0 }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          borderBottom: "1px solid #000",
                          py: 2,
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                        id={devHelper.getObjectValue(type, "_values.slug")}
                      >
                        {component.trans(
                          devHelper.getObjectValue(type, "_values.slug") +
                            "-title"
                        )}
                      </Typography>
                      <List
                        dense
                        sx={{ width: "100%", justifyContent: "top", p: 0 }}
                      >
                        {types_products.map((product, index) => {
                          return (
                            <ProductListView
                              key={devHelper.getObjectValue(product, "_id")}
                              {...{
                                ...props,
                                ...{
                                  product,
                                  ingredient_types,
                                  last: types_products.length === index + 1,
                                },
                              }}
                            />
                          );
                        })}
                      </List>
                    </Box>
                  );
                } else {
                  return (
                    <div key={devHelper.getObjectValue(type, "_id")}></div>
                  );
                }
              })}
            </>
          ) : (
            <>
              {[...new Array(5)].map((box, i) => (
                <Box
                  key={`title-skeleton-${i}`}
                  sx={{ px: { xs: 1, sm: 2 }, py: 0 }}
                >
                  <Skeleton
                    variant="text"
                    height={20}
                    width={120}
                    sx={{
                      transform: "none",
                      my: 2,
                    }}
                  />
                  <Skeleton variant="text" height={1} width="100%" />
                  <List
                    dense
                    sx={{ width: "100%", justifyContent: "top", p: 0 }}
                  >
                    {[...new Array(5)].map((product, index) => (
                      <ProductListSkeletonView
                        key={`skeleton-${index}`}
                        {...{
                          ...props,
                          ...{
                            index,
                          },
                        }}
                      />
                    ))}
                  </List>
                </Box>
              ))}
            </>
          )}
        </Paper>
      </Container>
      <ProductDialog
        {...{
          ...props,
          ...{},
        }}
      />
      <DealDialog
        {...{
          ...props,
          ...{},
        }}
      />
    </Box>
  );
}
